/* line 45, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__item {
	padding: 10px 15px;
	border-bottom: 1px solid rgb(var(--color-global-positive));
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

/* line 52, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__item:last-child {
	border: 0;
}

/* line 56, scss/80-themes/Saunders/60-components/rental-availability.scss */
.listing-info__box-rentalavailability .rental-availability__item:first-child {
	border-top: 1px solid rgb(var(--color-global-positive));
}

/* line 61, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__title {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	margin-top: 4px;
	color: rgb(var(--color-global-negative));
}

@media only screen and (max-width: 47.999em) {
	/* line 61, scss/80-themes/Saunders/60-components/rental-availability.scss */
	.rental-availability__title {
		font-size: 1.125em;
		line-height: 1.1108;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 61, scss/80-themes/Saunders/60-components/rental-availability.scss */
	.rental-availability__title {
		font-size: 1.125em;
		line-height: 1.222;
	}
}

@media only screen and (min-width: 80em) {
	/* line 61, scss/80-themes/Saunders/60-components/rental-availability.scss */
	.rental-availability__title {
		font-size: 1.125em;
		line-height: 1.222;
	}
}

/* line 82, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__item-period, .rental-availability__item-price, .rental-availability__value,
.rental-availability__value + .rental-availability__period {
	display: inline-block;
}

/* line 89, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__item-period, .rental-availability__item-price {
	box-sizing: border-box;
}

/* line 94, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__item-price {
	text-align: right;
	color: rgb(var(--color-global-text));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .rental-availability__item-price {
	text-align: left;
}

/* line 99, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__item-period {
	padding-right: 20px;
	text-transform: capitalize;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .rental-availability__item-period {
	padding-right: initial;
	padding-left: 20px;
}

/* line 110, scss/80-themes/Saunders/60-components/rental-availability.scss */
.listing-item__link:hover .rental-availability__period {
	color: rgb(var(--color-global-text));
}

/* line 114, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__period--normal, .rental-availability__period--route {
	font-size: 0.85em;
	text-transform: uppercase;
}

/* line 120, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__period--normal {
	color: rgb(var(--color-global-helper));
}

/* line 136, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__period--alt {
	text-transform: capitalize;
}

/* line 141, scss/80-themes/Saunders/60-components/rental-availability.scss */
.rental-availability__value + .rental-availability__period {
	padding-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .rental-availability__value + .rental-availability__period {
	padding-left: initial;
	padding-right: 5px;
}

/*# sourceMappingURL=../../../../true */